.App {
  text-align: left;
}

.App-logo {
  height: 25px;
  pointer-events: none;
}

.App-image{
  height:240px;
  padding-bottom: 10px;
}

.App-header {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color:#203A61;
  margin-top: 10px;
}

.Greet{
  padding: 20px 0px;
  font-weight: 700;
  font-size: 24px;
  color: #203A61;
}

.Button{
  background: #0078D4;
  border-radius: 5px;
  border: none;
  width: 212px;
  height: 66px;
  font-family: 'Space Mono';
  font-size: 18px;
  font-weight: 400;
  color: white;
  margin-top: 25px;
  cursor:pointer;
}

.Button:hover{
  background: white;
  color: #0078D4;
  border: 2px solid #0078D4;
}

.Row{
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 10px;
}

@media only screen and (max-width: 768px) {
  .App-header{
    flex-direction: column;
    font-size: 20px;
  }

  .App-image{
    height:200px;
    padding-bottom: 10px;
  }

  }